<div class="wrapper">
    <app-nav></app-nav>
    <app-sidebar></app-sidebar>

    <div class="content-wrapper" style="min-height: 1602px;">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

</div>
