import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkeletonComponent } from './layout/skeleton/skeleton.component';
import { INTERNAL_PATHS } from 'src/data/routes/internal.routes';
import { LandingComponent } from './layout/landing/landing.component';
import { AuthGuard } from './core/guards/auth.guard';
import { environment } from 'src/environments/environment';
import { PermissionsGuard } from './core/guards/permissions.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: INTERNAL_PATHS.AUTH_DEFAULT,
    loadChildren: () => import('./modulos/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: INTERNAL_PATHS.HOME,
    component: SkeletonComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    data:{roles:environment.authRoles.TODOS}
  },
  {
    path: INTERNAL_PATHS.ABM,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modulos/abm/abm.module').then((m) => m.AbmModule),   
  },
  {
    path: INTERNAL_PATHS.REPORTES,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modulos/reportes/reportes.module').then((m) => m.ReportesModule),
  }
  
  
  
   ,
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  }/*,
  {
    path: '**',
    redirectTo: '/landing'
  }, */

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
