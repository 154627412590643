import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../_services/auth.service';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpIntercep implements HttpInterceptor {

  constructor(private cookieService: CookieService, private router: Router) {
  }
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  const token: string = this.cookieService.get('token');
  let req = request;
  if (token) {
    req = request.clone({
      setHeaders: {
        authorization: `Bearer ${token}`
      }
    });
  }


  return next.handle(req).pipe(
    catchError((err: HttpErrorResponse) => {

      if (err.status === 401) {
        this.router.navigateByUrl('/login');
      }
      return throwError(err);

      })
    );
  }
}
  // token: string | null= null;
  // constructor(public auth: AuthService) {}

  // intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>>
  // {
  //   this.token = this.auth.getToken()
  //   if(this.token)
  //   {
  //     request = request.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ${this.token}`
  //       }
  //     });
  //   }
  //   return next.handle(request);
  // }
