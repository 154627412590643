import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { UserResponse, userToken } from 'src/app/models/User.interface';
import { AuthService } from 'src/app/_services/auth.service';
import { INTERNAL_PATHS, INTERNAL_ROUTES } from 'src/data/routes/internal.routes';
import { JwtHelperService } from '@auth0/angular-jwt'



const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isUserLogged:boolean = false;
  constructor(private authService: AuthService, private router: Router) { }

  canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('AUTH CAN ACTIVATE CHILD: '+ true);
    return this.canActivate(next);
  }


  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    this.authService.isLogged
    .subscribe((r)=>{this.isUserLogged = r.valueOf();})
    
    if(this.isUserLogged){
      return true;
    }else{
      console.log('No esta logueado')
      this.router.navigateByUrl(`${INTERNAL_ROUTES.AUTH_LOGIN}`);
      return false;
    }

  }
}

