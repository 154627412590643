import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})
export class ExporterService {

  constructor() { }

  exportToExcel(json:any[], excelFileName:string):void{
    const worksheet : XLSX.WorkSheet = XLSX.utils.json_to_sheet(json); //le paso el objeto json de la tabla y creo una hoja por json
    const workbook  : XLSX.WorkBook  = { //creo el book (o archivo) con todas las hojas y su nombre
      Sheets: {'data': worksheet},
      SheetNames: ['data']
    };

    const excelBuffer: any = XLSX.write(workbook, {bookType:'xlsx', type:'array'}); //guardo el archivo con la extension xlsx
    //Llamo al metodo que guarda el archivo (buffer y fileName)

    this.saveAsExcel(excelBuffer, excelFileName);//le paso el archivo que cree y el name que recibo al guardar
  }


  private saveAsExcel(buffer:any, fileName: string):void{ 
    const data: Blob = new Blob([buffer], {type:EXCEL_TYPE}); //Blob es una interface que soporta la creacion de archivos del sistema
    FileSaver.saveAs(data,fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);//Guardo el archivo y seteo el nombre que va a tener junto con la extension
  }
}
