export const ROUTES_PATHS =
{
    AUTH :{
        DEFAULT: 'auth',
        LOGIN: 'login',
        REGISTER: 'register'
    },
    HOME : {
        DEFAULT : 'home'
    },
    REPORTE:{
        DEFAULT: 'reportes',


        NEUMATICOREPORTE: 'neumatico-reporte',
        NEUMATICOHISTORICO: 'neumatico-historico',

        OBJETOREPORTE: 'objeto-reporte',
        OBJETOHISTORICO: 'objeto-historico',

        VEHICULOREPORTE: 'vehiculo-reporte',
        VEHICULOHISTORICO: 'vehiculo-historico',

        AUDITORIA:'auditorias',
    },
    ABM:{
      DEFAULT: 'abm',
      
      
      CONDUCTORLISTA: 'conductor-lista',
      CONDUCTORCREATE:'conductor-create',
      CONDUCTOREDIT:'conductor-edit',
      DESTINATARIOLISTA: 'destinatario-lista',
      ENTIDADLISTA: 'entidad-lista',
      ENTIDADTIPOLISTA: 'entidad-tipo-lista',
      LOCALIDADLISTA:'localidad-lista',
      
      //CLIENTE
      CLIENTECREATE:'cliente-create',
      CLIENTEEDIT:'cliente-edit',
      CLIENTELISTA:'cliente-lista',

      //NEUMATICO
      NEUMATICOLISTA:'neumatico-lista',
      NEUMATICOCREATE:'neumatico-create',
      NEUMATICOEDIT:'neumatico-edit',
      NEUMATICOMARCALISTA:'neumatico-marca-lista',
      NEUMATICOMARCACREATE:'neumatico-marca-create',
      NEUMATICOMARCAEDIT:'neumatico-marca-edit',
      NEUMATICOMEDIDALISTA:'neumatico-medida-lista',
      NEUMATICOMEDIDACREATE:'neumatico-medida-create',
      NEUMATICOMEDIDAEDIT:'neumatico-medida-edit',
      NEUMATICOMODELOLISTA:'neumatico-modelo-lista',
      NEUMATICOMODELOCREATE:'neumatico-modelo-create',
      NEUMATICOMODELOEDIT:'neumatico-modelo-edit',

      //OBJETOS
      OBJETOCREATE:'objeto-create',
      OBJETOEDIT:'objeto-edit',
      OBJETOLISTA:'objeto-lista',
      OBJETOMARCALISTA: 'objeto-marca-lista',
      OBJETOMARCAEDIT:'objeto-marca-edit',
      OBJETOMARCACREATE: 'objeto-marca-create',
      OBJETOTIPOLISTA: 'objeto-tipo-lista',
      OBJETOTIPOEDIT:'objeto-tipo-edit',
      OBJETOTIPOCREATE: 'objeto-tipo-create',
      OBJETOMODELOLISTA: 'objeto-modelo-lista',
      OBJETOMODELOEDIT:'objeto-modelo-edit',
      OBJETOMODELOCREATE: 'objeto-modelo-create',
    
      PROVINCIALISTA:'provincia-lista',

      //SUCURSAL
      SUCURSALCREATE:'sucursal-create',
      SUCURSALEDIT:'sucursal-edit',
      SUCURSALLISTA:'sucursal-lista',

      //VEHICULO
      VEHICULOCREATE:'vehiculo-create',
      VEHICULOEDIT:'vehiculo-edit',
      VEHICULOLISTA:'vehiculo-lista',
      VEHICULOMARCALISTA: 'vehiculo-marca-lista',
      VEHICULOMARCAEDIT:'vehiculo-marca-edit',
      VEHICULOMARCACREATE: 'vehiculo-marca-create',
      VEHICULOTIPOLISTA: 'vehiculo-tipo-lista',
      VEHICULOTIPOEDIT:'vehiculo-tipo-edit',
      VEHICULOTIPOCREATE: 'vehiculo-tipo-create',
      VEHICULOMODELOLISTA: 'vehiculo-modelo-lista',
      VEHICULOMODELOEDIT:'vehiculo-modelo-edit',
      VEHICULOMODELOCREATE: 'vehiculo-modelo-create',

    },

   ERROR : {
        FORBIDDEN: 'forbidden'
    }

}

export const INTERNAL_PATHS = {
    HOME :`${ROUTES_PATHS.HOME.DEFAULT}`,
    ABM :`${ROUTES_PATHS.ABM.DEFAULT}`, 
    AUTH_DEFAULT : `${ROUTES_PATHS.AUTH.DEFAULT}`,
    AUTH_LOGIN : `${ROUTES_PATHS.AUTH.LOGIN}`,
    AUTH_REGISTER : `${ROUTES_PATHS.AUTH.REGISTER}`,

    

    CONDUCTOR_LISTA: `${ROUTES_PATHS.ABM.CONDUCTORLISTA}`,
    CONDUCTOR_CREATE: `${ROUTES_PATHS.ABM.CONDUCTORCREATE}`,
    CONDUCTOR_EDIT: `${ROUTES_PATHS.ABM.CONDUCTOREDIT}`,
    DESTINATARIO_LISTA:`${ROUTES_PATHS.ABM.DESTINATARIOLISTA}`,
    ENTIDAD_LISTA:`${ROUTES_PATHS.ABM.ENTIDADLISTA}`,
    ENTIDAD_TIPO_LISTA:`${ROUTES_PATHS.ABM.ENTIDADTIPOLISTA}`,
    LOCALIDAD_LISTA:`${ROUTES_PATHS.ABM.LOCALIDADLISTA}`,
    PROVINCIA_LISTA:`${ROUTES_PATHS.ABM.PROVINCIALISTA}`,
    
    //-----ABM-------
    //CLIENTES
    CLIENTE_CREATE:`${ROUTES_PATHS.ABM.CLIENTECREATE}`,
    CLIENTE_EDIT:`${ROUTES_PATHS.ABM.CLIENTEEDIT}`,
    CLIENTE_LISTA:`${ROUTES_PATHS.ABM.CLIENTELISTA}`,

    //NEUMATICOS
    NEUMATICO_CREATE:`${ROUTES_PATHS.ABM.NEUMATICOCREATE}`,
    NEUMATICO_EDIT:`${ROUTES_PATHS.ABM.NEUMATICOEDIT}`,
    NEUMATICO_LISTA:`${ROUTES_PATHS.ABM.NEUMATICOLISTA}`,
    NEUMATICO_MARCA_CREATE:`${ROUTES_PATHS.ABM.NEUMATICOMARCACREATE}`,
    NEUMATICO_MARCA_EDIT:`${ROUTES_PATHS.ABM.NEUMATICOMARCAEDIT}`,
    NEUMATICO_MARCA_LISTA:`${ROUTES_PATHS.ABM.NEUMATICOMARCALISTA}`,
    NEUMATICO_MEDIDA_CREATE:`${ROUTES_PATHS.ABM.NEUMATICOMEDIDACREATE}`,
    NEUMATICO_MEDIDA_EDIT:`${ROUTES_PATHS.ABM.NEUMATICOMEDIDAEDIT}`,
    NEUMATICO_MEDIDA_LISTA:`${ROUTES_PATHS.ABM.NEUMATICOMEDIDALISTA}`,
    NEUMATICO_MODELO_CREATE:`${ROUTES_PATHS.ABM.NEUMATICOMODELOCREATE}`,
    NEUMATICO_MODELO_EDIT:`${ROUTES_PATHS.ABM.NEUMATICOMODELOEDIT}`,
    NEUMATICO_MODELO_LISTA:`${ROUTES_PATHS.ABM.NEUMATICOMODELOLISTA}`,


    //OBJETOS
    OBJETO_CREATE:`${ROUTES_PATHS.ABM.OBJETOCREATE}`,
    OBJETO_EDIT:`${ROUTES_PATHS.ABM.OBJETOEDIT}`,
    OBJETO_LISTA:`${ROUTES_PATHS.ABM.OBJETOLISTA}`,
    OBJETO_MARCA_CREATE:`${ROUTES_PATHS.ABM.OBJETOMARCACREATE}`,
    OBJETO_MARCA_EDIT:`${ROUTES_PATHS.ABM.OBJETOMARCAEDIT}`,
    OBJETO_MARCA_LISTA:`${ROUTES_PATHS.ABM.OBJETOMARCALISTA}`,
    OBJETO_MODELO_CREATE:`${ROUTES_PATHS.ABM.OBJETOMODELOCREATE}`,
    OBJETO_MODELO_EDIT:`${ROUTES_PATHS.ABM.OBJETOMODELOEDIT}`,
    OBJETO_MODELO_LISTA:`${ROUTES_PATHS.ABM.OBJETOMODELOLISTA}`,
    OBJETO_TIPO_CREATE:`${ROUTES_PATHS.ABM.OBJETOTIPOCREATE}`,
    OBJETO_TIPO_EDIT:`${ROUTES_PATHS.ABM.OBJETOTIPOEDIT}`,
    OBJETO_TIPO_LISTA:`${ROUTES_PATHS.ABM.OBJETOTIPOLISTA}`,
    
    //SUCURSAL
    SUCURSAL_CREATE:`${ROUTES_PATHS.ABM.SUCURSALCREATE}`,
    SUCURSAL_EDIT:`${ROUTES_PATHS.ABM.SUCURSALEDIT}`,
    SUCURSAL_LISTA:`${ROUTES_PATHS.ABM.SUCURSALLISTA}`,

    //VEHICULOS
    VEHICULO_CREATE:`${ROUTES_PATHS.ABM.VEHICULOCREATE}`,
    VEHICULO_EDIT:`${ROUTES_PATHS.ABM.VEHICULOEDIT}`,
    VEHICULO_LISTA:`${ROUTES_PATHS.ABM.VEHICULOLISTA}`,
    VEHICULO_MARCA_LISTA : `${ROUTES_PATHS.ABM.VEHICULOMARCALISTA}`,
    VEHICULO_MARCA_CREATE : `${ROUTES_PATHS.ABM.VEHICULOMARCACREATE}`,
    VEHICULO_MARCA_EDIT : `${ROUTES_PATHS.ABM.VEHICULOMARCAEDIT}`,
    VEHICULO_TIPO_LISTA : `${ROUTES_PATHS.ABM.VEHICULOTIPOLISTA}`,
    VEHICULO_TIPO_CREATE : `${ROUTES_PATHS.ABM.VEHICULOTIPOCREATE}`,
    VEHICULO_TIPO_EDIT : `${ROUTES_PATHS.ABM.VEHICULOTIPOEDIT}`,
    VEHICULO_MODELO_LISTA : `${ROUTES_PATHS.ABM.VEHICULOMODELOLISTA}`,
    VEHICULO_MODELO_CREATE : `${ROUTES_PATHS.ABM.VEHICULOMODELOCREATE}`,
    VEHICULO_MODELO_EDIT : `${ROUTES_PATHS.ABM.VEHICULOMODELOEDIT}`,


    //------REPORTES-----
    REPORTES:`${ROUTES_PATHS.REPORTE.DEFAULT}`,
    
    NEUMATICO_HISTORICO:`${ROUTES_PATHS.REPORTE.NEUMATICOHISTORICO}`,
    NEUMATICO_REPORTE:`${ROUTES_PATHS.REPORTE.DEFAULT}`,
    
    OBJETO_HISTORICO:`${ROUTES_PATHS.REPORTE.OBJETOHISTORICO}`,
    OBJETO_REPORTE:`${ROUTES_PATHS.REPORTE.OBJETOREPORTE}`,
   
    VEHICULO_HISTORICO:`${ROUTES_PATHS.REPORTE.VEHICULOHISTORICO}`,
    VEHICULO_REPORTE:`${ROUTES_PATHS.REPORTE.VEHICULOREPORTE}`,
    
    AUDITORIA_REPORTE:`${ROUTES_PATHS.REPORTE.AUDITORIA}`,
}

export const INTERNAL_ROUTES = {
    //HOME
    HOME : `/${INTERNAL_PATHS.HOME}`,
    //AUTH
    AUTH_LOGIN : `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_LOGIN}`,
    AUTH_REGISTER : `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_REGISTER}`,

    //ABM
    CONDUCTOR_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CONDUCTOR_LISTA}`,
    CONDUCTOR_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CONDUCTOR_CREATE}`,
    CONDUCTOR_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CONDUCTOR_EDIT}`,
    DESTINATARIO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.DESTINATARIO_LISTA}`,
    ENTIDAD_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.ENTIDAD_LISTA}`,

    ENTIDAD_TIPO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.ENTIDAD_TIPO_LISTA}`,
    LOCALIDAD_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.LOCALIDAD_LISTA}`,
    PROVINCIA_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.PROVINCIA_LISTA}`,

    CLIENTE_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CLIENTE_LISTA}`,
    CLIENTE_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CLIENTE_CREATE}`,
    CLIENTE_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.CLIENTE_EDIT}`,
    
    //ABM NEUMATICOS
    NEUMATICO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_LISTA}`,
    NEUMATICO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_CREATE}`,
    NEUMATICO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_EDIT}`,
    NEUMATICO_MARCA_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MARCA_LISTA}`,
    NEUMATICO_MARCA_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MARCA_CREATE}`,
    NEUMATICO_MARCA_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MARCA_EDIT}`,
    NEUMATICO_MEDIDA_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MEDIDA_LISTA}`,
    NEUMATICO_MEDIDA_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MEDIDA_CREATE}`,
    NEUMATICO_MEDIDA_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MEDIDA_EDIT}`,
    NEUMATICO_MODELO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MODELO_LISTA}`,
    NEUMATICO_MODELO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MODELO_CREATE}`,
    NEUMATICO_MODELO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.NEUMATICO_MODELO_EDIT}`,

    //ABM OBJETOS
    OBJETO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_LISTA}`,
    OBJETO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_CREATE}`,
    OBJETO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_EDIT}`,
    OBJETO_MARCA_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MARCA_LISTA}`,
    OBJETO_MARCA_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MARCA_CREATE}`,
    OBJETO_MARCA_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MARCA_EDIT}`,
    OBJETO_MODELO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MODELO_LISTA}`,
    OBJETO_MODELO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MODELO_CREATE}`,
    OBJETO_MODELO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_MODELO_EDIT}`,
    OBJETO_TIPO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_TIPO_LISTA}`,
    OBJETO_TIPO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_TIPO_CREATE}`,
    OBJETO_TIPO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.OBJETO_TIPO_EDIT}`,

    //ABM SUCURSALES
    SUCURSAL_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.SUCURSAL_CREATE}`,
    SUCURSAL_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.SUCURSAL_EDIT}`,
    SUCURSAL_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.SUCURSAL_LISTA}`,

    //ABM VEHICULOS
    VEHICULO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_CREATE}`,
    VEHICULO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_EDIT}`,
    VEHICULO_LISTA:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_LISTA}`,
    
    VEHICULO_MARCA_LISTA: `/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MARCA_LISTA}`,
    VEHICULO_MARCA_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MARCA_CREATE}`,
    VEHICULO_MARCA_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MARCA_EDIT}`,
    
    VEHICULO_TIPO_LISTA: `/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_TIPO_LISTA}`,
    VEHICULO_TIPO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_TIPO_CREATE}`,
    VEHICULO_TIPO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_TIPO_EDIT}`,
    
    VEHICULO_MODELO_LISTA: `/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MODELO_LISTA}`,
    VEHICULO_MODELO_CREATE:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MODELO_CREATE}`,
    VEHICULO_MODELO_EDIT:`/${INTERNAL_PATHS.ABM}/${INTERNAL_PATHS.VEHICULO_MODELO_EDIT}`,
    

    //REPORTE
    //NEUMATICO
    NEUMATICO_HISTORICO:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.NEUMATICO_HISTORICO}`,
    NEUMATICO_REPORTE:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.NEUMATICO_REPORTE}`,

    //OBJETO
    OBJETO_HISTORICO:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.OBJETO_HISTORICO}`,
    OBJETO_REPORTE:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.OBJETO_REPORTE}`,

    //VEHICULO
    VEHICULO_HISTORICO:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.VEHICULO_HISTORICO}`,
    VEHICULO_REPORTE:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.VEHICULO_REPORTE}`,
   
   AUDITORIA_REPORTE:`/${INTERNAL_PATHS.REPORTES}/${INTERNAL_PATHS.AUDITORIA_REPORTE}`,
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   //REPORTES
    // REPORTES_TOTALES_ASISTENCIAS :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_ASISTENCIAS}`,
    // REPORTES_TOTALES_PROYECTOS :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_PROYECTOS}`,
    // REPORTES_TOTALES_ASISTENCIAS_Y_PROYECTOS :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_ASISTENCIAS_Y_PROYECTOS}`,
    // REPORTES_TOTALES_HORAS :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_HORAS}`,
    // REPORTES_TOTALES_HORAS_GERENCIA :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_HORAS_GERENCIA}`,
    // REPORTES_TOTALES_HORAS_JEFATURA :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_HORAS_JEFATURA}`,
    // REPORTES_TOTALES_FACTURACION_PG :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_FACTURACION_PG}`,
    // REPORTES_CUENTAS_POR_COBRAR :`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_CUENTAS_POR_COBRAR}`,
    // REPORTES_CUENTAS_POR_PAGAR:`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_CUENTAS_POR_PAGAR}`,
    // REPORTES_OCUPACION_JPS:`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_OCUPACION_JPS}`,
    // REPORTES_OCUPACION_PGS:`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_OCUPACION_PGS}`,
    // REPORTES_TOTALES_FACTURACION:`/${INTERNAL_PATHS.REPORTES_DEFAULT}/${INTERNAL_PATHS.REPORTES_TOTALES_FACTURACION}`,
    // //POLIVALENCIAS
    // POLIVALENCIAS_CONOCIMIENTOS: `/${INTERNAL_PATHS.POLIVALENCIAS_DEFAULT}/${INTERNAL_PATHS.POLIVALENCIAS_CONOCIMIENTOS}`,
    // POLIVALENCIAS_GENERAL: `/${INTERNAL_PATHS.POLIVALENCIAS_DEFAULT}/${INTERNAL_PATHS.POLIVALENCIAS_GENERAL}`,

    // //ERROR
    // ERROR_FORBIDDEN: `/${INTERNAL_PATHS.ERROR_FORBIDDEN}`,
}
