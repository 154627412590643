import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt'
import { userToken } from 'src/app/models/User.interface';



const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private authService:AuthService, private router:Router) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    
      const token = this.authService.getToken();
      const tokenDecode:userToken = helper.decodeToken(token);

      const userRoles = tokenDecode.role;
      var roles = route.data.roles;

      for (const rol of roles) {
        if (userRoles && userRoles === rol) {
          return true;
        }
      }
      return false;
  }
  
}
