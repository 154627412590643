
//''
import { environment as ENV } from "src/environments/environment"

export const API_ROUTES= {
    AUTH: {
        LOGIN : `${ENV.apiUrl}Negocio/Identity/Login`,
        REGISTER : `Account/registro`,
        TEST_CONNECTION : `Account/TestConnection`,
       
    },
    ABM:
    {   
        /* ---------- APIS CONDUCTORES ------------ */
        GET_ALL_CONDUCTORES:`${ENV.apiUrl}Negocio/Conductor/GetAllConductor`,
        DELETE_CONDUCTORES:`${ENV.apiUrl}Negocio/Conductor/DeleteConductor?ConductorId=`,
        CREATE_CONDUCTORES:`${ENV.apiUrl}Negocio/Conductor/PostConductor`,
        GET_ONE_CONDUCTOR:`${ENV.apiUrl}Negocio/Conductor/GetOneConductor?ConductorId=`,


        /* ---------- APIS CLIENTES ------------ */
        GET_ALL_CLIENTES:`${ENV.apiUrl}Negocio/Cliente/GetAllClientes`,
        DELETE_ONE_CLIENTES:`${ENV.apiUrl}Negocio/Cliente/DeleteCliente?ClienteId=`,
        POST_ONE_CLIENTES:`${ENV.apiUrl}Negocio/Cliente/PostCliente`,
        GET_ONE_CLIENTES:`${ENV.apiUrl}Negocio/Cliente/GetOneCliente?ClienteId=`,



        /* ---------- APIS NEUMATICOS ------------ */
        GET_ALL_NEUMATICOS:`${ENV.apiUrl}Negocio/Neumatico/GetAllNeumatico`,
        GET_ONE_NEUMATICOS:`${ENV.apiUrl}Negocio/Neumatico/GetOneNeumatico?NeumaticoId=`,
        POST_ONE_NEUMATICOS:`${ENV.apiUrl}Negocio/Neumatico/PostNeumatico`,
        DELETE_NEUMATICOS:`${ENV.apiUrl}Negocio/Neumatico/DeleteNeumatico?NeumaticoId=`,

        GET_ALL_NEUMATICOS_MARCA:`${ENV.apiUrl}Negocio/NeumaticoMarca/GetAllMarcaNeumatico`,
        GET_ONE_NEUMATICOS_MARCA:`${ENV.apiUrl}Negocio/NeumaticoMarca/GetOneNeumaticoMarca?NeumaticoMarcaId=`,
        POST_ONE_NEUMATICOS_MARCA:`${ENV.apiUrl}Negocio/NeumaticoMarca/PostNeumaticoMarca`,
        DELETE_NEUMATICOS_MARCA:`${ENV.apiUrl}Negocio/NeumaticoMarca/DeleteNeumaticoMarca?NeumaticoMarcaId=`,

        GET_ALL_NEUMATICOS_MEDIDA:`${ENV.apiUrl}Negocio/NeumaticoMedida/GetAllMedidaNeumatico`,
        GET_ONE_NEUMATICOS_MEDIDA:`${ENV.apiUrl}Negocio/NeumaticoMedida/GetOneNeumaticoMedida?NeumaticoMedidaID=`,
        POST_ONE_NEUMATICOS_MEDIDA:`${ENV.apiUrl}Negocio/NeumaticoMedida/PostNeumaticoMedida`,
        DELETE_NEUMATICO_MEDIDA:`${ENV.apiUrl}Negocio/NeumaticoMedida/DeleteNeumaticoMedida?NeumaticoMedidaId=`,
        // DELETE_NEUMATICOS_MEDIDA:`${ENV.apiUrl}Negocio/NuematicoMedida/DeleteNeumaticoMedida?NeumaticoMedidaId=`,
        
        GET_ALL_NEUMATICOS_MODELO:`${ENV.apiUrl}Negocio/NeumaticoModelo/GetAllNeumaticoModelo?Marca=`,
        GET_ONE_NEUMATICOS_MODELO:`${ENV.apiUrl}Negocio/NeumaticoModelo/GetOneObjetoSimpleModelo?idNeumaticoModelo`,
        POST_ONE_NEUMATICOS_MODELO:`${ENV.apiUrl}Negocio/NeumaticoModelo/PostNeumaticoModelo`,
        DELETE_NEUMATICOS_MODELO:`${ENV.apiUrl}Negocio/NeumaticoModelo/DeleteNeumaticoModelo?idNeumaticoModelo=`,
                                              

        /* ---------- APIS OBJETOS ------------ */

        GET_ALL_OBJETOS:`${ENV.apiUrl}Negocio/ObjetoSimple/GetAllObjetoSimple`,
        GET_ONE_OBJETOS:`${ENV.apiUrl}Negocio/ObjetoSimple/GetOneObjetoSimple?ObjetoSimpleId=`,
        POST_ONE_OBJETOS:`${ENV.apiUrl}Negocio/ObjetoSimple/PostObjetoSimple`,
        DELETE_OBJETOS:`${ENV.apiUrl}Negocio/ObjetoSimple/DeleteObjetoSimple?ObjetoSimpleId=`,
        
        GET_ALL_OBJETOS_MARCA:`${ENV.apiUrl}Negocio/ObjetoSimpleMarca/GetAllObjetoSimpleMarca`,
        GET_ONE_OBJETOS_MARCA:`${ENV.apiUrl}Negocio/ObjetoSimpleMarca/GetOneObjetoSimpleMarca?ObjetoSimpleMarcaId=`,
        POST_ONE_OBJETOS_MARCA:`${ENV.apiUrl}Negocio/ObjetoSimpleMarca/PostObjetoSimpleMarca`,
        DELETE_OBJETOS_MARCA:`${ENV.apiUrl}Negocio/ObjetoSimpleMarca/DeleteObjetoSimpleMarca?ObjetoSimpleMarcaId=`,
        
        GET_ALL_OBJETOS_MODELO:`${ENV.apiUrl}Negocio/ObjetoSimpleModelo/GetAllObjetoSimpleModelo`,
        GET_ONE_OBJETOS_MODELO:`${ENV.apiUrl}Negocio/ObjetoSimpleModelo/GetOneObjetoSimpleModelo?ObjetoSimpleModeloId=`,
        POST_ONE_OBJETOS_MODELO:`${ENV.apiUrl}Negocio/ObjetoSimpleModelo/PostObjetoSimpleModelo`,
        DELETE_OBJETOS_MODELO:`${ENV.apiUrl}Negocio/ObjetoSimpleModelo/DeleteObjetoSimpleModelo?ObjetoSimpleModeloId=`,

        GET_ALL_OBJETOS_TIPO:`${ENV.apiUrl}Negocio/ObjetoSimpleTipo/GetAllObjetoSimpleTipo`,
        GET_ONE_OBJETOS_TIPO:`${ENV.apiUrl}Negocio/ObjetoSimpleTipo/GetOneObjetoSimpleTipo?ObjetoSimpleTipoId=`,
        POST_ONE_OBJETOS_TIPO:`${ENV.apiUrl}Negocio/ObjetoSimpleTipo/PostObjetoSimpleTipo`,
        DELETE_OBJETOS_TIPO:`${ENV.apiUrl}Negocio/ObjetoSimpleTipo/DeleteObjetoSimpleTipo?ObjetoSimpleTipoId=`,


        /* ---------- APIS SUCURSAL ------------ */
        GET_ALL_SUCURSAL:`${ENV.apiUrl}Negocio/Sucursal/GetAllSucursal?ClienteID=`,
        GET_ONE_SUCURSAL:`${ENV.apiUrl}Negocio/Sucursal/GetOneSucursal?SucursalID=`,
        POST_ONE_SUCURSAL:`${ENV.apiUrl}Negocio/Sucursal/PostSucursal`,
        DELETE_SUCURSAL:`${ENV.apiUrl}Negocio/Sucursal/DeleteSucursal?SucursalId=`,

        
        /* ---------- APIS VEHICULOS ------------ */
        GET_ALL_VEHICULOS:`${ENV.apiUrl}Negocio/Vehiculo/GetAllVehiculo`,
        GET_ONE_VEHICULOS:`${ENV.apiUrl}Negocio/Vehiculo/GetOnevehiculo?VehiculoId=`,
        POST_ONE_VEHICULOS:`${ENV.apiUrl}Negocio/Vehiculo/PostVehiculo`,
        DELETE_VEHICULOS:`${ENV.apiUrl}Negocio/Vehiculo/Deletevehiculo?VehiculoId=`,
        
        GET_ALL_VEHICULOS_MARCA:`${ENV.apiUrl}Negocio/VehiculoMarca/GetAllvehiculoMarca`,
        GET_ONE_VEHICULOS_MARCA:`${ENV.apiUrl}Negocio/VehiculoMarca/GetOnevehiculoMarca?VehiculoMarcaId=`,
        POST_ONE_VEHICULOS_MARCA:`${ENV.apiUrl}Negocio/VehiculoMarca/PostVehiculoMarca`,
        DELETE_VEHICULOS_MARCA:`${ENV.apiUrl}Negocio/VehiculoMarca/DeletevehiculoMarca?VehiculoMarcaId=`,
        
        GET_ALL_VEHICULOS_MODELO:`${ENV.apiUrl}Negocio/VehiculoModelo/GetAllVehiculoModelos`,
        GET_ONE_VEHICULOS_MODELO:`${ENV.apiUrl}Negocio/VehiculoModelo/GetOnevehiculoModelo?VehiculoModeloId=`,
        POST_ONE_VEHICULOS_MODELO:`${ENV.apiUrl}Negocio/VehiculoModelo/PostVehiculoModelo`,
        DELETE_VEHICULOS_MODELO:`${ENV.apiUrl}Negocio/VehiculoModelo/DeletevehiculoModelo?VehiculoModeloId=`,

        GET_ALL_VEHICULOS_TIPO:`${ENV.apiUrl}Negocio/VehiculoTipo/GetAllVehiculoTipo`,
        GET_ONE_VEHICULOS_TIPO:`${ENV.apiUrl}Negocio/VehiculoTipo/GetOnevehiculoTipo?VehiculoTipoId=`,
        POST_ONE_VEHICULOS_TIPO:`${ENV.apiUrl}Negocio/VehiculoTipo/PostVehiculoTipo`,
        DELETE_VEHICULOS_TIPO:`${ENV.apiUrl}Negocio/VehiculoTipo/DeletevehiculoTipo?VehiculoTipoId=`,
        GET_ALL_IMAGEN_VEHICULOS:`${ENV.apiUrl}Negocio/ImagenVehiculo/GetAllImagenes`,
        GET_ONE_IMAGEN_VEHICULOS:`${ENV.apiUrl}Negocio/ImagenVehiculo/GetOne?Id=`,
    },
    REPORTES:{
        //APIS REPORTES
        GET_ALL_VERIFICACION_NEUMATICO:`${ENV.apiUrl}Negocio/VerificacionNeumatico/GetAllVerificacionNeumatico?idVerificacionNeumatico=`,
        GET_ALL_VERIFICACION_OBJETO:`${ENV.apiUrl}Negocio/VerificacionObjeto/GetAllVerificacionObjeto?idVerificacionVehiculo=`,
        GET_ONE_VERIFICACION_NEUMATICO:`${ENV.apiUrl}Negocio/VerificacionNeumatico/GetAllVerificacionNeumaticoById?idNeumatico=`,
        GET_ONE_VERIFICACION_OBJETO:`${ENV.apiUrl}Negocio/VerificacionObjeto/GetAllVerificacionObjetoById?idObjetos=`,
        GET_ALL_VERIFICACION_VEHICULO:`${ENV.apiUrl}Negocio/VerificacionVehiculo/GetAllVerificacionVehiculo?idVerificacionVehiculo=`,
        GET_ALL_AUDITORIAS:`${ENV.apiUrl}Negocio/Auditoria/GetAllAuditorias`,
    }
}