import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { API_ROUTES } from 'src/data/routes/api.routes';
import { INTERNAL_PATHS, INTERNAL_ROUTES } from 'src/data/routes/internal.routes';
import { IApiUserAuthenticated } from 'src/data/enum';

import { JwtHelperService } from '@auth0/angular-jwt'
import { CookieService } from 'ngx-cookie-service';
import { User, UserResponse, userToken } from '../models/User.interface';

const helper = new JwtHelperService();
@Injectable({
  providedIn:'root'
})

export class AuthService {

  public currentUser: BehaviorSubject<IApiUserAuthenticated>;
  public nameUserLS = 'currentUserSGC';

  public isUserLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService:CookieService
  ) {
    this.checkToken();
  }

  get isLogged(): Observable<boolean> {
    return this.isUserLoggedIn.asObservable();
  }

  login(data: User): Observable<UserResponse | void>  {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http    
    .post(API_ROUTES.AUTH.LOGIN, data ,  requestOptions)
      .pipe(
        map((response:string)=>{
          const tokenDecode = helper.decodeToken(response);
          console.log(tokenDecode);
          this.cookieService.set('token',response);
          this.cookieService.set('rol',this.getTokenData().role);
          this.isUserLoggedIn.next(true);
          this.router.navigateByUrl(`/${INTERNAL_PATHS.ABM}`);
          window.location.reload();
        }),
        catchError((error)=> this.handlerError(error))
      );
  }


  logout():void {
    this.cookieService.delete('token','/');
    this.cookieService.delete('token','/abm');
    this.cookieService.delete('token','/reportes');
    this.cookieService.delete('rol','/');
    this.cookieService.delete('rol','/abm');
    this.cookieService.delete('rol','/reportes');
    this.isUserLoggedIn.next(false);
    location.reload();
    this.router.navigateByUrl(`${INTERNAL_ROUTES.AUTH_LOGIN}`);
  }



  private handlerError(error: any): Observable<never> {
    let errorMensaje = ''
    if (error) {
      errorMensaje = `Error: code ${error.message}`;
    }
    // window.alert(errorMensaje)
    return throwError(errorMensaje);
  }

  private checkToken(): void {
    const data = this.cookieService.get('token');
    console.log('comprobando token');
    if (data) {
      const isExpired = helper.isTokenExpired(data);
      console.log("Is EXPIRED --->", isExpired );
      isExpired ?  this.logout() : this.isUserLoggedIn.next(true); //Si espira se desloguea y si no devolvemos el observable en true
    }
    else {
      //  console.log('Token -> Lo hago falso');
      console.log('es falso');
      this.isUserLoggedIn.next(false);
      // this.blnLoggedIn = false;
      //  console.log('blnLoggedIn en checkToken -> Lo hago false ', this.blnLoggedIn);
    }
  }




  public getToken() {
    const data = this.cookieService.get('token');

     if (data) {
        const isExpired = helper.isTokenExpired(data);
        //    console.log('isExpired ->', isExpired);
        if (isExpired) {
          this.isUserLoggedIn.next(false);
          return null;
        }
        else {
          this.isUserLoggedIn.next(true);
          return data;
        }
      }else{
        this.isUserLoggedIn.next(false);
        return null;
       }
  }


  register(data: any): Observable<any> {
    return this.http.post(API_ROUTES.AUTH.REGISTER, data)
  }

  public getTokenData():userToken{
    const data = this.cookieService.get('token');
    return (helper.decodeToken(data));
  }

}
