import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/_services/auth.service';
import { INTERNAL_ROUTES } from 'src/data/routes/internal.routes';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  //STR ABM

  //STR VEHICULOS
  public STR_VEHICULO_LISTA = INTERNAL_ROUTES.VEHICULO_LISTA
  public STR_VEHICULO_MARCA_LISTA = INTERNAL_ROUTES.VEHICULO_MARCA_LISTA
  public STR_VEHICULO_TIPO_LISTA = INTERNAL_ROUTES.VEHICULO_TIPO_LISTA
  public STR_VEHICULO_MODELO_LISTA = INTERNAL_ROUTES.VEHICULO_MODELO_LISTA
  
  public STR_CONDUCTOR_LISTA = INTERNAL_ROUTES.CONDUCTOR_LISTA
  public STR_DESTINATARIO_LISTA = INTERNAL_ROUTES.DESTINATARIO_LISTA
  public STR_ENTIDAD_LISTA = INTERNAL_ROUTES.ENTIDAD_LISTA
  public STR_ENTIDAD_TIPO_LISTA = INTERNAL_ROUTES.ENTIDAD_TIPO_LISTA
  public STR_LOCALIDAD_LISTA = INTERNAL_ROUTES.LOCALIDAD_LISTA
  public STR_PROVINCIA_LISTA = INTERNAL_ROUTES.PROVINCIA_LISTA
  
  //STR NEUMATICOS
  public STR_NEUMATICO_LISTA = INTERNAL_ROUTES.NEUMATICO_LISTA
  public STR_NEUMATICO_MARCA_LISTA = INTERNAL_ROUTES.NEUMATICO_MARCA_LISTA
  public STR_NEUMATICO_MEDIDA_LISTA = INTERNAL_ROUTES.NEUMATICO_MEDIDA_LISTA
  public STR_NEUMATICO_MODELO_LISTA = INTERNAL_ROUTES.NEUMATICO_MODELO_LISTA

  //STR OBJETO
  public STR_OBJETO_LISTA = INTERNAL_ROUTES.OBJETO_LISTA
  public STR_OBJETO_MARCA_LISTA = INTERNAL_ROUTES.OBJETO_MARCA_LISTA
  public STR_OBJETO_MODELO_LISTA = INTERNAL_ROUTES.OBJETO_MODELO_LISTA
  public STR_OBJETO_TIPO_LISTA = INTERNAL_ROUTES.OBJETO_TIPO_LISTA
  

  //STR REPORTE
  //STR NEUMATICO
  public STR_NEUMATICO_REPORTE = INTERNAL_ROUTES.NEUMATICO_REPORTE;

  public STR_AUDITORIA_REPORTE = INTERNAL_ROUTES.AUDITORIA_REPORTE;

  //STR OBJETO
  public STR_OBJETO_HISTORICO = INTERNAL_ROUTES.OBJETO_HISTORICO;
  public STR_OBJETO_REPORTE = INTERNAL_ROUTES.OBJETO_REPORTE;

  //STR VEHICULO
  public STR_VEHICULO_HISTORICO = INTERNAL_ROUTES.VEHICULO_HISTORICO;
  public STR_VEHICULO_REPORTE = INTERNAL_ROUTES.VEHICULO_REPORTE;


  //STR VEHICULO
  public STR_SUCURSAL = INTERNAL_ROUTES.SUCURSAL_LISTA;
  public STR_CLIENTE = INTERNAL_ROUTES.CLIENTE_LISTA;
  
  constructor( private authService: AuthService ) {
    this.getRol();
   }

  ngOnInit(): void {
  }

  getRol(){
    const data = this.authService.getTokenData();
    const rol = data.role;
    if (rol !== environment.authRoles.SUPER_ADMIN[0] ){
      return false;
    } 
    return true;
  }
}
