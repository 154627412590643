import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NeumaticoGeneral } from 'src/app/models/Neumaticos';
import { ObjetoSimpleGeneral, ObjetoSimpleGeneralOne } from 'src/app/models/Objetos.interface';
import { VehiculoGeneral } from 'src/app/models/Vehiculos.interface';
import { AbmService } from 'src/app/_services/api/abm.service';
import { AuthService } from 'src/app/_services/auth.service';
import { INTERNAL_ROUTES } from 'src/data/routes/internal.routes';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  visibility:boolean = true;
  date: Date = new Date();
  proximosVencimientos:ObjetoSimpleGeneral[] = [];
  rol:string='';
  nombre:string='';


  constructor( private abmService:AbmService,
              private authService:AuthService,
              private router:Router) {
    this.abmService.getObjetos()
    .subscribe(
      (r:ObjetoSimpleGeneral[]) => {
        r.map((objeto:ObjetoSimpleGeneral)=>{
          if (objeto) {
            if(this.diferenciaObjFecha(objeto) >= 0 && this.diferenciaObjFecha(objeto) <= 15){
              this.proximosVencimientos.push(objeto); //agrego el objeto a mostrar
            }
          }
        })

      }
    );

    this.rol = this.authService.getTokenData().role;
    this.nombre = this.authService.getTokenData().unique_name;
  }

  ngOnInit(): void {
    if(this.proximosVencimientos.length === 0){
      this.visibility = false;
    }
  }

  toggleBadgeVisibility():void{
    this.visibility = false;
  }
  logout(){
    this.authService.logout();
    this.router.navigateByUrl(`${INTERNAL_ROUTES.AUTH_LOGIN}`)
  }


  diferenciaObjFecha(objeto:ObjetoSimpleGeneral):number{
   /* SEPARANDO LA FECHA */
    var array1 = objeto.fechaProximoMantenimiento?.split('T');
    var array2 = array1[0].split('-');
    var fechaObjeto = new Date(Number(array2[0]),Number(array2[1])-1,Number(array2[2]));


    /* RESTA DE FECHAS */
    var resta = fechaObjeto.getTime() - this.date.getTime();
    const resultado = Math.round(resta/ (1000*60*60*24)); /*  60 seg que tiene un minuto * 60 minutos que tiene una hora * 24 hrs que posee regularmente un día.
                                                              la multuplicación por 1000 es para reducir tres cantidades al momento final del resultado */
    return resultado;
  }
}
