import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { INTERNAL_PATHS, INTERNAL_ROUTES } from 'src/data/routes/internal.routes';
declare var $ : any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ["../../../../node_modules/normalize.css/normalize.css",
  '../../../assets/css/bootstrap.min.css',
  '../../../assets/css/font-awesome.min.css',
  '../../../assets/css/owl.carousel.css',
  '../../../assets/css/owl.transitions.css',
  './landing.component.css']
})

export class LandingComponent implements OnInit {
  

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    $(document).ready(function () {
      $("#main-slider").owlCarousel({
        navigation: true,
        singleItem: true,
        autoPlay: true,
        dots: true,
        play: 5000
      });
      
    });
    $(document).on('click', '.navbar-collapse.in', function (e) {
      if ($(e.target).is('a')) {
        $(this).collapse('hide');
      }
    });

    $(function () {
      $('.goTo').on('click', function (e) {
        e.preventDefault();
        var aniLocation = $(this).attr('href');

        if ($(window).width() > 700) {
          $('body, html').animate({
            scrollTop: $(aniLocation).offset().top
          }, {
            duration: 1000,
            queue: false,
            specialEasing: {
              width: "linear",
              height: "easeInOutExpo"
            }
          });
        } else {
          $('body, html').animate({
            scrollTop: $(aniLocation).offset().top - 150
          }, {
            duration: 1000,
            queue: false,
            specialEasing: {
              width: "linear",
              height: "easeInOutExpo"
            }
          });
        }
      });
      $('.scrollTop').on('click', function (event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 1000);
      });
    });
   }

  ngOnInit(): void { }

  login(){
    const resultado = this.authService.getToken();
    if (resultado == null) {
      this.router.navigateByUrl(`${INTERNAL_ROUTES.AUTH_LOGIN}`);
    } else {
      this.router.navigateByUrl(`${INTERNAL_PATHS.ABM}`);
    }
  }

  validar(name, email, message){
    $("#contact_form_fail_SinCampos").hide();
    $("#contact_form_fail").hide();
    $("#contact_form_ok").hide();
    var nomb = $('#name').val();
    var email = $('#email').val();
    var mensaje = $('#message').val()
    if (nomb == "" || email == "" || mensaje == ""){
      console.log("hola")
      $("#contact_form_fail_SinCampos").show('slow');
    }
  }

}
