<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="['']" class="nav-link">axTyres</a>
        </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown position-relative" (click)="toggleBadgeVisibility()">
            <a class="nav-link dropdown-toggle"  data-toggle="dropdown" href="#" role="button">
                <i class="far fa-bell"></i>
                <span *ngIf="visibility" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {{proximosVencimientos.length}}
                </span>
            </a>
            <ul *ngIf="proximosVencimientos.length > 0; else noHayVencimientos" class="dropdown-menu dropdown-menu-right">
                <div *ngFor="let vencimiento of proximosVencimientos">
                    <li>
                        <a class="dropdown-item" href="#">
                            <h4>
                                Nombre: {{vencimiento.nombre}} <br> Fecha: {{vencimiento.fechaProximoMantenimiento | date:'short'}}
                            </h4><hr>
                        </a>
                        
                    </li>
                </div>
            </ul>
            <ng-template #noHayVencimientos>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                        <a class="dropdown-item" href="#">
                            <h4>
                                No hay notificaciones
                            </h4>
                        </a>
                        
                    </li>
                </ul>
            </ng-template>
        </li>
  <!--       <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa bell"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-item dropdown-header"></span>
                <div class="dropdown-divider"></div>
                <a href="auth/register" class="dropdown-item" >
                    <i class="fas fa-users mr-2"></i>Crear nuevo usuario
                </a>
                <div class="dropdown-divider"></div>
                <a [routerLink]="" class="dropdown-item" >
                    <i class="fas fa-sign-out-alt mr-2"></i> Cerrar sesión
                </a>
            </div>
        </li> -->
        <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-user"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-item dropdown-header">{{nombre}} | {{rol}}</span>
                <div class="dropdown-divider"></div>
                <a href="auth/register" class="dropdown-item" >
                    <i class="fas fa-users mr-2"></i>Crear nuevo usuario
                </a>
                <div class="dropdown-divider"></div>
                <a [routerLink]="" class="dropdown-item" (click)="logout()">
                    <i class="fas fa-sign-out-alt mr-2"></i> Cerrar sesión
                </a>
            </div>
        </li>
    </ul>
</nav>