<body>
	<header>
		<div class="container">
			<div class="row">
				<div class="col-xs-4">
					<div class="topleft"></div>
					<a href="https://axtyres.com" class="brand">
						<img src="../../../assets/images/logo-top.png" alt="AX Tyres" title="AX Tyres">
					</a>
				</div>
				<div class="col-xs-8">
					<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#menu"
						aria-expanded="false">
						<i class="fa fa-bars fa-2x"></i>
					</button>
					<div class="collapse navbar-collapse" id="menu">
						<nav>
							<ul class="nav navbar-nav navbar-right">
								<li><a class="goTo" href="#institucional">axTYRES</a></li>
								<li><a class="goTo" href="#contacto">CONTACTO</a></li>
								<li><a routerLink="/auth/login">LOGIN</a></li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</header>
	<section class="main-slider">
		<div id="main-slider" class="owl-carousel">
				<div class="item">
					<img src="../../../assets/images/slide-1.jpg" alt="Slide1" />
					<div class="caption">
						<div class="container">
							<div class="row">
								<div class="col-xs-12">
									<div class="caption-1">
										<h1><b>axTYRES</b><br>SOFTWARE DE GESTION<br>Y CONTROL DE
											<br>NEUMATICOS
										</h1>
									</div>
									<div class="caption-2">
										<h1>EXTENDÉ<br> SU VIDA UTIL</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="item">
					<img src="../../../assets/images/slide-2.jpg" alt="Slide2" />
					<div class="caption">
						<div class="container">
							<div class="row">
								<div class="col-xs-12">
									<div class="caption-1">
										<h1><b>axTYRES</b><br>SOFTWARE DE GESTION<br>Y CONTROL DE <br>NEUMATICOS</h1>
									</div>
									<div class="caption-2">
										<h1>ECONOMIZÁ EN EL<br> CONSUMO DE<br> COMBUSTIBLE</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			<div class="item">
				<img src="../../../assets/images/slide-3.jpg" alt="Slide3" />
				<div class="caption">
					<div class="container">
						<div class="row">
							<div class="col-xs-12">
								<div class="caption-1">
									<h1><b>axTYRES</b><br>SOFTWARE DE GESTION<br>Y CONTROL DE <br>NEUMATICOS</h1>
								</div>
								<div class="caption-2">
									<h1>HACÉ MAS SEGURA<br> LA CONDUCCIÓN<br> PREVENÍ ACCIDENTES</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	
	</section>
	
	<section id="institucional" class="institucional">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h3>axTYRES</h3>
					<p>Ahora Ud. puede controlar los neumáticos y accesorios (matafuegos, lonas, baterías, radios, etc.) de su
						flota de vehículos gracias a la tecnología RFID, para esto solo es necesario poner un tag (chip
						electrónico) en cada uno de sus neumáticos y/o accesorios y un tag en cada vehículo. Con el uso de un
						colector de datos móviles, quedan referenciados el vehículo, con los neumáticos, accesorios y conductor.
					</p>
				</div>
			</div>
		</div>
	</section>
	
	<section id="violeta" class="violeta">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h3>¿Cómo iniciar?</h3>
					<p>Para comenzar a utilizarlo es muy simple, contratá el software de acuerdo con el tamaño de su flota, se
						le proveen los lectores y tags necesarios para su sistema y se siguen los siguientes pasos básicos: </p>
				</div>
			</div><br>
			<div class="row">
				<div class="col-xs-12">
					<div class="number">1 <span></span><i class="fa fa-arrow-right"></i></div>
					<p><b>CONFIGURACIÓN INICIAL: </b> Configuración inicial del sistema utilizando la plataforma web.</p>
				</div>
			</div><br>
			<div class="row">
				<div class="col-xs-12">
					<div class="number">2 <span></span><i class="fa fa-arrow-right"></i></div>
					<p><b>ASIGNACIÓN DE TAGS: </b> Asignación de tags a todos los elementos, vehículos, neumáticos y accesorios
						utilizando el colector de datos móviles.</p>
				</div>
			</div><br>
			<div class="row">
				<div class="col-xs-12">
					<div class="number">3 <span></span><i class="fa fa-arrow-right"></i></div>
					<p><b>VINCULACIÓN: </b> Vinculación entre el vehículo, conductor, neumáticos y accesorios al momento que el
						vehículo está por salir de la empresa.</p>
				</div>
			</div><br>
			<div class="row">
				<div class="col-xs-12">
					<div class="number">4 <span></span><i class="fa fa-arrow-right"></i></div>
					<p><b>VERIFICACIÓN:</b> Verificación del vehículo cuando retorna, utilizando el colector de datos móviles y
						el medidor de presión y altura de taco electrónico con comunicación Bluetooth.</p>
				</div>
			</div><br>
			<div class="row">
				<div class="col-xs-12">
					<div class="number">5 <span></span><i class="fa fa-arrow-right"></i></div>
					<p><b>REVISIÓN:</b> Revisión de los reportes del sistema para análisis de durabilidad de los neumáticos y
						accesorios.</p>
				</div>
			</div>
		</div>
	</section>
	
	<section id="institucional" class="institucional">
		<div class="container">
			<div class="row">
				<div class="col-xs-12">
					<h3>Vinculación y verificación</h3>
					<p>Tanto en la vinculación como en la verificación se ingresan los kilómetros del vehículo, para que se
						vayan almacenando los kms recorridos por cada neumático, incluso de los auxiliares.</p>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<h3>Finalidad</h3>
					<p>De esta manera se logra tener un control contra fraudes o robos de neumáticos y tener registrado los
						kilómetros de uso de cada uno, y así poder hacer un análisis de la durabilidad de cada tipo y/o marca de
						neumáticos, chofer que lo utiliza, entre otros, sabiendo que después del vehículo, los neumáticos son el
						activo más costoso de su flota.</p>
				</div>
			</div>
		</div>
	</section>
	
	<section id="contacto" class="contacto">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-11 col-md-6 col-lg-6">						
					<h3>Contacto</h3>
					<input type="hidden" name="recaptcha_response" id="recaptchaResponse">
					<div class="form">
	
						<!-- Form de contacto  -->
						<form name="contact_form" id="contact_form" action="https://formsubmit.co/info@axtyres.com" method="POST">
							<div class="row">
								<input type="hidden" name="_next" value="https://axtyres.com/gracias.html">
								<input type="hidden" name="_subject" value="Nueva Consulta">
								<input type="hidden" name="_captcha" value="false">
								<input type="hidden" name="_template" value="table">
								<div class="col-xs-12 col-sm-6">
									<div class="form-group">
										<input type="text" class="form-control" name="Nombre" id="name" placeholder="Nombre" required />
									</div>
								</div>
								<div class="col-xs-12 col-sm-6">
									<div class="form-group">
										<input type="text" class="form-control" name="Telefono" id="phone" placeholder="Teléfono" />
									</div>
								</div>
								<div class="col-xs-12 col-sm-6">
									<div class="form-group">
										<input type="email" class="form-control" name="Email" id="email" placeholder="E-mail" required email />
									</div>
								</div>
								<div class="col-xs-12 col-sm-6">
									<div class="form-group">
										<input type="text" class="form-control" name="Ciudad" id="city" placeholder="Localidad">
									</div>
								</div>
								<div class="col-xs-12">
									<div class="form-group">
										<textarea name="Mensaje" id="message" rows="8" class="form-control" placeholder="Mensaje" required></textarea>
									</div>
								</div>
	
								<div class="col-xs-12 form-action" style="text-align: center;">
									<div id="mensajes">
										<div id="contact_form_fail" style="display:none">
											<p class='thanks_error'>Hubo un error en el envío.<br />Intente nuevamente más tarde.</p>
										</div>
										<div id="contact_form_fail_SinCampos" style="display:none">
											<p class='thanks_error'>Por favor, complete todos los datos obligatorios para poder enviar la consulta</p>
										</div>
										<div id="contact_form_ok" style="display:none">
											<p class='thanks'>¡Consulta enviada!</p>
										</div>
									</div>

									<div class="g-recaptcha" data-sitekey="6LeAv3MdAAAAAHp8QoyYl8H9SxBKJb3DnK06Q0Xl">
										<div style="width: 304px; height: 78px;">
											<div>
												<iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Lf6wDcdAAAAANgSMEyb6yAqvY2NUi3JeLuNl7C1&amp;co=aHR0cDovL2hvdXN0b24uY29tLmFyOjgw&amp;hl=en&amp;v=_7Co1fh8iT2hcjvquYJ_3zSP&amp;size=normal&amp;cb=vf2eq13s4pgf" width="304" height="78" role="presentation" name="a-yle79xvqypck" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe>
											</div>
											<textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea>
										</div>
										<iframe style="display: none;"></iframe>
									</div>
									
									<br>
									<button id="sending" type="submit" class="btn-lg btn-primary" onclick = "validar($('#name').val(), $('#email').val(), $('#message').val(),)">											
										ENVIAR
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<footer>
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-8 col-md-6 col-lg-6">
						<p class="copyright">axTyres - ©Copyright 2021 - Todos los derechos reservados</p>
						<p class="copyright">
							<a href="http://www.houston.com.ar" target="_blank"><img alt="Logo Houston" src="../../../assets/images/houston.svg"
									width="57" height="8"></a>
						</p>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-6 col-lg-6 logo-foot">
						<a href="http://www.aumax.com.ar/">
							<img src="../../../assets/images/logo-pie.png" class="logo" alt="Logo AuMax" title="AuMax" /></a>
						<div class="foot-rightbottom"></div>
					</div>
				</div>
			</div>
		</footer>
	</section>
</body>