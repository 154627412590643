import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { API_ROUTES } from "src/data/routes/api.routes";
import { ImagenVehiculo } from "src/app/models/Imagen.interface";


@Injectable({
    providedIn: 'root'
  })
  export class AbmService {

    constructor(private http: HttpClient) { }
      
      getConductores():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_CONDUCTORES);     
      }

      deleteConductor(ConductorId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_CONDUCTORES + ConductorId);     
      }

      createConductor(data:any):Observable<any>
      {
        console.log(API_ROUTES.ABM.CREATE_CONDUCTORES);
        console.log(data);
        return this.http.post(API_ROUTES.ABM.CREATE_CONDUCTORES, data);
      }

      getOneConductor(data:number): Observable<any>
      {
        return this.http.get(API_ROUTES.ABM.GET_ONE_CONDUCTOR + data);
      }



      getAllClientes():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_CLIENTES);     
      }

      deleteOneCliente(id:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_ONE_CLIENTES + id);     
      }

      postOneCliente(data:any):Observable<any>
      {
        return this.http.post(API_ROUTES.ABM.POST_ONE_CLIENTES, data);
      }

      getOneCliente(id:number): Observable<any>
      {
        return this.http.get(API_ROUTES.ABM.GET_ONE_CLIENTES + id);
      }



      /* ---------- METODOS VEHICULOS ------------ */
      //VEHICULOS GENERAL
      getAllVehiculos():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_VEHICULOS);     
      }
      getOneVehiculos(VehiculoId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ONE_VEHICULOS + VehiculoId);
      }
      postOneVehiculos(formulario:any):Observable<any>{
        return this.http.post(API_ROUTES.ABM.POST_ONE_VEHICULOS, formulario);
      }
      deleteVehiculos(VehiculoId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_VEHICULOS + VehiculoId);
      }
      
      //VEHICULOS MARCA
      getAllVehiculosMarca():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_VEHICULOS_MARCA);     
      }
      getOneVehiculosMarca(VehiculoMarcaId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ONE_VEHICULOS_MARCA + VehiculoMarcaId);
      }
      postOneVehiculosMarca(formulario:any):Observable<any>{
        return this.http.post(API_ROUTES.ABM.POST_ONE_VEHICULOS_MARCA, formulario);
      }
      deleteVehiculosMarca(VehiculoMarcaId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_VEHICULOS_MARCA + VehiculoMarcaId);
      }

      
      
      //VEHICULOS MODELO
      getAllVehiculosModelo():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_VEHICULOS_MODELO);     
      }
      
      getOneVehiculosModelo(VehiculoModeloId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ONE_VEHICULOS_MODELO + VehiculoModeloId);
      }

      postOneVehiculosModelo(formulario:any):Observable<any>{
        return this.http.post(API_ROUTES.ABM.POST_ONE_VEHICULOS_MODELO, formulario);
      }
      
      deleteVehiculosModelo(VehiculoModeloId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_VEHICULOS_MODELO + VehiculoModeloId);
      }


      //VEHICULOS TIPO
      getAllVehiculosTipo():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_VEHICULOS_TIPO);     
      }
      
      getOneVehiculosTipo(VehiculoTipoId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ONE_VEHICULOS_TIPO + VehiculoTipoId);
      }

      postOneVehiculosTipo(formulario:any):Observable<any>{
        return this.http.post(API_ROUTES.ABM.POST_ONE_VEHICULOS_TIPO, formulario);
      }
      
      deleteVehiculosTipo(VehiculoTipoId:number):Observable<any>{
        return this.http.get(API_ROUTES.ABM.DELETE_VEHICULOS_TIPO + VehiculoTipoId);
      }
      getAllImagenes(){
        return this.http.get(API_ROUTES.ABM.GET_ALL_IMAGEN_VEHICULOS)
      }
      getOneImagen(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_IMAGEN_VEHICULOS + id)
      }


      /* ---------- METODOS OBJETOS ------------ */
      //OBJETOS SIMPLES GENERAL
      getObjetos():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_OBJETOS);     
      }
      getOneObjetos(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_OBJETOS + id);  
      }
      postOneObjetos(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_OBJETOS, formulario);  
      }
      deleteOneObjetos(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_OBJETOS + id);  
      }


      //OBJETOS SIMPLES TIPO
      getObjetosTipo():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_OBJETOS_TIPO);     
      }
      getOneObjetosTipo(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_OBJETOS_TIPO + id);  
      }
      postOneObjetosTipo(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_OBJETOS_TIPO, formulario);  
      }
      deleteOneObjetosTipo(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_OBJETOS_TIPO + id);  
      }

      //OBJETOS SIMPLES MARCA
      getObjetosMarca():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_OBJETOS_MARCA);     
      }
      getOneObjetosMarca(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_OBJETOS_MARCA + id);  
      }
      postOneObjetosMarca(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_OBJETOS_MARCA, formulario);  
      }
      deleteOneObjetosMarca(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_OBJETOS_MARCA + id);  
      }


      //OBJETOS SIMPLES MODELO //todo
      getObjetosModelo(idMarca?:number,idTipo?:number):Observable<any>{
        var url = API_ROUTES.ABM.GET_ALL_OBJETOS_MODELO;
        if(idMarca !== undefined && idTipo !== undefined){
          url = `${API_ROUTES.ABM.GET_ALL_OBJETOS_MODELO}?Marca=${idMarca}&Tipo=${idTipo}`
        }

        return this.http.get(url);     
      }
      getOneObjetosModelo(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_OBJETOS_MODELO + id);  
      }
      postOneObjetosModelo(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_OBJETOS_MODELO, formulario);  
      }
      deleteOneObjetosModelo(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_OBJETOS_MODELO + id);  
      }


      /* ---------- METODOS SUCURSAL ------------ */

      getAllSucursales(id:number){

        return this.http.get(API_ROUTES.ABM.GET_ALL_SUCURSAL + id );  
      }
      getOneSucursal(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_SUCURSAL + id);  
      }
      postOneSucursal(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_SUCURSAL, formulario);  
      }
      deleteOneSucursal(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_SUCURSAL + id);  
      }

      /* ---------- METODOS NEUMATICOS ------------ */
      getNeumaticos():Observable<any>{
        return this.http.get(API_ROUTES.ABM.GET_ALL_NEUMATICOS);     
      }
      getOneNeumaticos(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_NEUMATICOS + id);  
      }
      postOneNeumaticos(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_NEUMATICOS, formulario);  
      }
      deleteOneNeumaticos(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_NEUMATICOS + id);  
      }
      



      getAllNeumaticosMarca(){
        return this.http.get(API_ROUTES.ABM.GET_ALL_NEUMATICOS_MARCA);  
      }      
      getOneNeumaticosMarca(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_NEUMATICOS_MARCA + id);  
      }
      postOneNeumaticosMarca(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_NEUMATICOS_MARCA, formulario);  
      }
      deleteOneNeumaticosMarca(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_NEUMATICOS_MARCA + id);  
      }


      getAllNeumaticosMedida(){
        return this.http.get(API_ROUTES.ABM.GET_ALL_NEUMATICOS_MEDIDA);  
      }      
      getOneNeumaticosMedida(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ONE_NEUMATICOS_MEDIDA + id);  
      }
      postOneNeumaticosMedida(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_NEUMATICOS_MEDIDA, formulario);  
      }
      deleteOneNeumaticosMedida(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_NEUMATICO_MEDIDA + id);  
      }

      getAllNeumaticosModelo(id:number){
        return this.http.get(API_ROUTES.ABM.GET_ALL_NEUMATICOS_MODELO + id);  
      }      
      getOneNeumaticosModelo(id:number){
        return this.http.get(`${API_ROUTES.ABM.GET_ONE_NEUMATICOS_MODELO}=${id}`);  
      }
      postOneNeumaticosModelo(formulario: any){
        return this.http.post(API_ROUTES.ABM.POST_ONE_NEUMATICOS_MODELO, formulario);  
      }
      deleteOneNeumaticosModelo(id:number){
        return this.http.get(API_ROUTES.ABM.DELETE_NEUMATICOS_MODELO + id);  
      }
  }