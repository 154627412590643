<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="['']" class="brand-link d-flex align-items-center justify-content-between mx-5">
        
        <img id="logoAumax" src="../../../assets/images/logo.png" alt="Logo AuMax" class="brand-image img-circle" >
        <img id="logoAxTyres" src="../../../assets/images/logo-top.png" alt="Logo AxTyres" class="brand-image img-circle" >
        <!-- <span class="brand-text font-weight-light axTyres">axTyres</span> -->
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user (optional) -->


        <!-- SidebarSearch Form -->
        <!-- <div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
                </div>
            </div>
            <div class="sidebar-search-results">
                <div class="list-group">
                    <a class="list-group-item">
                        <div class="search-title"><strong class="text-light"></strong>N<strong class="text-light"></strong>o<strong class="text-light"></strong> <strong class="text-light"></strong>e<strong class="text-light"></strong>l<strong class="text-light"></strong>e
                            <strong class="text-light"></strong>m<strong class="text-light"></strong>e<strong class="text-light"></strong>n<strong class="text-light"></strong>t<strong class="text-light"></strong> <strong class="text-light"></strong>f
                            <strong class="text-light"></strong>o
                            <strong class="text-light"></strong>u<strong class="text-light"></strong>n<strong class="text-light"></strong>d<strong class="text-light"></strong>!<strong class="text-light"></strong></div>
                        <div class="search-path"></div>
                    </a>
                </div>
            </div>
        </div> -->

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                <li class="nav-item" >
                    <a class="nav-link" style="cursor: pointer;">
                        <p>
                            ABM Vehículos
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item" >
                            <a [routerLink]="STR_VEHICULO_TIPO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Vehículo Tipo</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="STR_VEHICULO_MARCA_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Vehículo Marca</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="STR_VEHICULO_MODELO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Vehículo Modelo</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="STR_VEHICULO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Vehículo</p>
                            </a>                           
                        </li>
                    </ul>
                </li>
                <li class="nav-item" >
                    <a class="nav-link" style="cursor: pointer;">
                        <i ></i>
                        <p>
                            ABM Neumáticos
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                <ul class="nav nav-treeview">
                    <li class="nav-item" >
                        <a [routerLink]="STR_NEUMATICO_MARCA_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Neumático Marca</p>
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a [routerLink]="STR_NEUMATICO_MEDIDA_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Neumático Medida</p>
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a [routerLink]="STR_NEUMATICO_MODELO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Neumático Modelo</p>
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a [routerLink]="STR_NEUMATICO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Neumático</p>
                        </a>
                    </li>
                </ul>
                </li>
                <li class="nav-item" >
                    <a class="nav-link" style="cursor: pointer;">
                        <i ></i>
                        <p>
                            ABM Objetos Simples
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                <ul class="nav nav-treeview">
                    <li class="nav-item" >
                        <a [routerLink]="STR_OBJETO_TIPO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Objetos Simples Tipo</p>
                        </a>
                    </li>                        
                    <li class="nav-item" >
                        <a [routerLink]="STR_OBJETO_MARCA_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Objetos Simples Marca</p>
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a [routerLink]="STR_OBJETO_MODELO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Objetos Simples Modelo</p>
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a [routerLink]="STR_OBJETO_LISTA" [routerLinkActive]="['active']" class=" nav-link ">
                            <i class="far fa-circle nav-icon "></i>
                            <p>Objetos Simples</p>
                        </a>
                    </li>
                </ul>
                </li>
                <li class="nav-item" >
                    <a class="nav-link" style="cursor: pointer;">
                        <i ></i>
                        <p>
                            ABM Conductores
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item" >
                            <a  [routerLink]="STR_CONDUCTOR_LISTA" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Conductor</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" *ngIf="getRol()" >
                    <a class="nav-link" style="cursor: pointer;">
                        <i ></i>
                        <p>
                            ABM Sucursal - Cliente
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item" >
                            <a  [routerLink]="STR_SUCURSAL" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Sucursal</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a  [routerLink]="STR_CLIENTE" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Cliente</p>
                            </a>
                        </li>
                    </ul>
                </li>
                       

                <li class="nav-item" >
                    <a class="nav-link" style="cursor: pointer;">
                        <i ></i>
                        <p>
                            Reportes
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <!-- <li class="nav-item" >
                            <a   [routerLink]="STR_NEUMATICO_REPORTE" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Neumáticos</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a   [routerLink]="STR_OBJETO_REPORTE" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Objetos</p>
                            </a>
                        </li> -->
                        <li class="nav-item" >
                            <a   [routerLink]="STR_AUDITORIA_REPORTE" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Auditorias</p>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a   [routerLink]="STR_VEHICULO_REPORTE" [routerLinkActive]="['active']"  class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p>Vehículos</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>