import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import * as fromComponents from './components'
 import { MaterialModule } from './material/material.module';
import { MatTableExporterModule } from 'mat-table-exporter';
import { HttpIntercep } from '../_services/token-interceptor';
import { AuthService } from '../_services/auth.service';
import { AuthGuard } from '../core/guards/auth.guard';


@NgModule({
  declarations: [],
  imports: [
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      CommonModule,
       MaterialModule,
      
      
    // FormsModule,
    // ReactiveFormsModule,    
    // HttpClientModule,
    // CommonModule,
    // NgbModule,
    // MaterialModule,
    MatTableExporterModule
  ],
  exports :[
     FormsModule,
     ReactiveFormsModule,    
     HttpClientModule,
     CommonModule,
    // NgbModule,
      MaterialModule,
     MatTableExporterModule,
    // ...fromComponents.components
  ],
  providers:[    
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercep,
      multi: true
    },
    AuthService
  ]
})
export class SharedModule { }
